import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const EsGuidesTricksPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Tips and tricks</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/eversoul/categories/category_tips.png"
            alt="Tips and tricks"
          />
        </div>
        <div className="page-details">
          <h1>Tips and tricks</h1>
          <h2>Compilation of important tricks and tips for the game.</h2>
          <p>
            Last updated: <strong>09/06/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Generic tips" />
        <ul>
          <li>
            Use the available codes as soon as they are released - they usually
            give you more gems and pulls. You can find new codes on our Discord
            or on the Home page here.
          </li>
          <li>
            Unlike other Idle games, Rerolling is worthwhile here – please check
            our Reroll Guide:
          </li>
        </ul>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Reroll Guide"
            link="/eversoul/guides/reroll"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_reroll.jpg"
                alt="Tier list"
              />
            }
          />
        </Row>
        <ul>
          <li>
            Also, during the first 2 days you will do roughly 200+ pulls - you
            will obtain a lot of tickets and Everstones from progressing and
            from current events.
          </li>
          <li>
            In the Release Soul screen tap the checkbox to automatically release
            Normal rarity units - this will save you a lot of time.
          </li>
        </ul>
        <SectionHeader title="Everstones spending" />
        <ul>
          <li>
            <strong>
              Do a 10-pull as soon as you obtain enough tickets and/or enough
              Everstones (2100).
            </strong>
          </li>
          <li>
            90% of the Everstones you earn should be used on summoning new
            characters. The game heavily relies on dupes and fodder so without
            pulling you will get stuck.
          </li>
        </ul>
        <SectionHeader title="Progression" />
        <StaticImage
          src="../../../images/eversoul/generic/guide_progression.jpg"
          alt="Guide"
        />
        <ul>
          <li>
            Keep your main damage dealer 10 levels above the rest of the team
            and level the rest equally - this will help you progress faster.
          </li>
          <li>
            While in the first 6 chapters, you can use a random team with Epics
            you've pulled, after that abusing the Faction bonuses and using
            characters with synergy becomes important. Try to build a team with
            at least the 3 same faction bonus once you reach Chapter 7.
          </li>
        </ul>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Team building"
            link="/eversoul/guides/team-building"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_team.png"
                alt="Team building"
              />
            }
          />
        </Row>
        <ul>
          <li>
            Try different Formations! Sometimes even switching characters within
            a formation can heavily affect the outcome of the battle.
          </li>
          <li>
            The free copy of <strong>Rebecca</strong> that you obtain after
            clearing Chapter 5 is a godsend. She's an anti-healer, which makes
            her perfect to counter the healing-heavy teams you start meeting in
            Chapter 7 and above.
          </li>
          <li>
            <strong>
              The Hire Soul feature (found in Friends tab) makes progression so
              much easier
            </strong>
            . You can daily hire 5 characters from your friends and use each
            once to complete a stage - only when you clear a stage, it will
            count as used, so don't be afraid to try various things and fail.
            Compared to other idle games, where you can hire a character for a
            whole week and also only use it once, here you can re-hire them
            every day! So go and grab some high level friends to carry you.
          </li>
          <li>
            <strong>
              Don't instantly open resource boxes and only use them when you
              actually need it (so when you hit a wall).
            </strong>
            . How many resources you will obtain from a box depends on your
            progress in the story - so opening them later will give you more.
          </li>
        </ul>
        <SectionHeader title="Level Sync" />
        <StaticImage
          src="../../../images/eversoul/generic/guide_levelsync.jpg"
          alt="Guide"
        />
        <ul>
          <li>
            <strong>
              Level Sync allows you to bypass level cap restrictions
            </strong>{' '}
            - if you want to use a character badly in your team, but she is at
            her level cap, throw her into the Level Sync! If your top 5
            characters are above the level that limits her, she will be leveled
            beyond it.
          </li>
          <li>
            This means that you can Ascend 5 Rare characters (4 from different
            factions and you double up on one) up to Legendary+ (which
            translates to 160 level cap) - later down the line, you can Fooder
            them to your Epics to push them even further, so nothing was wasted.
          </li>
          <li>
            Don't be afraid to try different characters. You can reset them at
            anytime and get 100% resources used to level them - it costs a bit
            of Everstones, but if you bought the Monthly Pass, it's free.
          </li>
          <li>
            Unlocking a few more slots for Everstones isn't a bad investment -
            it will allow you to progress easier through the Faction Gates and
            the rewards there are actually really damn good.
          </li>
          <li>
            Removing someone from the Synchro Device has a small penalty
            attached to it - you need to wait 4h to add a new character in that
            spot again.
          </li>
        </ul>
        <SectionHeader title="Overclock" />
        <StaticImage
          src="../../../images/eversoul/generic/guide_ocunlock.webp"
          alt="Guide"
        />
        <p>
          Introduced shortly before First Anniversary, the Overclock system is
          aimed at resolving the Mana Dust throttle at higher levels.
        </p>
        <ul>
          <li>
            The Overclocking feature will be available after reaching 300 sync
            level of [Level Sync] and 100 level of Main Ark System in [Ark
            Enhance].
          </li>
          <li>
            The maximum limit of Overclocking will be increased according to the
            level of [Main Ark System] in the [The Ark] - [Ark Enhance].
          </li>
          <li>You can increase Overclocking level with Mana Crystals.</li>
          <li>
            When the Overclocking level is increased, the level of the Souls of
            Level Sync will be increased according to the Overclocking level.
          </li>
          <ul>
            <li>
              Overclocking level will be also applied to the 5 Souls of the max
              level, who determines the synced level. But the level will not
              exceed the sum of the sync level and Overclock level.
            </li>
          </ul>
        </ul>
        <StaticImage
          src="../../../images/eversoul/generic/guide_oclevel.webp"
          alt="Guide"
        />
        <p>Examples:</p>
        <ul>
          <li>
            Previous (The level of highest 5 Souls): 350 / 305 / 300 / 300 / 300
            = Sync Level 300
          </li>
          <li>Overclock Level: 10</li>
          <li>
            Result (Overclocked highest 5 Souls): 350 / 310 / 310 / 310 / 310 =
            Sync Level 310
          </li>
          <li>
            You can reset the Overclocking level with 500 Everstones every 30
            days.
          </li>
        </ul>
        <SectionHeader title="Game modes" />
        <ul>
          <li>
            Do your Hall of Memories run as close to the weekly reset as
            possible during the first few weeks. The reason for doing is that
            you will gain power quickly in the early game and unlock higher
            difficulties - if you do the lowest one right after unlocking the
            mode, you will gain a nice boost of resources, but you will on a lot
            more stuff that's locked behind higher difficulties.
          </li>
          <li>
            In Gate Breakthrough, once you unlock the faction Gates (need to
            clear stage 8-35), even a 3-man team will be enough to push the
            first 10-20 levels, so don't be afraid of trying. The rewards there
            are worth it.
          </li>
          <li>
            Make sure to push Dual Gates content as further as you can and aim
            for Mana Crystals as they are a major block to increasing your level
            as you play. After a while (not sure how 'soon will it be on JP')
            but the dual gates content will be changed to Decoy Operations. This
            mode let's you obtain either of Mana Crystals, Gold, Mana Dust or
            Keepsake Stones daily and is a great way to obtain the resources you
            need. You have 3 tickets to spend daily and can pick a different
            Operation for each ticket. Additional tickets can be purchased with
            Everstones.
          </li>
          <li>
            Participate in all the Event raids and fights as they give lot of
            resources for minimal effort.
          </li>
          <li>
            Farming Arena points effectively during 2x Point Events is easy and
            quick way to get your Ayame copies.
          </li>
          <li>
            Tower of Origin is a game mode that provides extra backstory on the
            featured soul, while also awarding players with 300 of their
            artifact shard, a flash deal giving you a copy of the unit+100 more
            shards and a lot of mana crystals, gold and artifact tickets/stones.
          </li>
        </ul>
        <SectionHeader title="Zodiac system" />
        <StaticImage
          src="../../../images/eversoul/generic/guide_zodiac.jpg"
          alt="Guide"
        />
        <ul>
          <li>
            You get one point to spend her for each account level. So at level
            50 account level, you will have 50 points to spend.
          </li>
          <li>
            While investing into Signs that give you stats is tempting, don't do
            that. Instead first you should invest into those 3 Signs: Aries,
            Cancer, and Pisces. They increase the amount of Gold, Mana Crystal
            and Mana Dust you obtain from idle rewards.
          </li>
          <li>
            Mana Crystal Blessing is recommended to activate early on as it is
            the major roadblock.
          </li>
        </ul>
        <SectionHeader title="Artifacts" />
        <StaticImage
          src="../../../images/eversoul/generic/guide_artifacts.jpg"
          alt="Guide"
        />
        <ul>
          <li>
            Once you unlock Artifacts (need to clear stage 4-10), you will be
            able to use the Artifact banner. We suggest to add Artifacts for the
            characters you to use the Wishlist first and pull 1 copy only.
          </li>
          <li>
            Artifacts give a character not only a decent stat boost, but if you
            pair an artifact with its proper owner, you will also unlock an
            additional skill.
          </li>
          <li>
            Don't focus on pulling dupes for the Artifacts until you have
            Artifacts unlocked for all characters you use/have, as it would be
            much more worthwhile to do the same. Once you have the required
            artifacts, Please check the High Investment units tab on our Tier
            list page to see what character artifact worth the most investment.
          </li>
        </ul>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Tier list"
            link="/eversoul/tier-list"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_tierlist.jpg"
                alt="Tier list"
              />
            }
          />
        </Row>
        <ul>
          <li>
            Don't enhance or level artifacts yet (only level one to 4 for the
            quest).
          </li>
        </ul>
        <SectionHeader title="Town" />
        <ul>
          <li>
            Prioritize buying the Building imprints instead of trees and other
            similar things. While they give the same Fame (as its tied to the
            cost), buildings actually allow you to unlock more missions and also
            add characters to some that improve their stats in combat.
          </li>
          <li>
            Visit friend's towns daily and kill the monsters there. For each
            town you clear, you will get 10 Hearts which equals to 1 pull on the
            Friendly banner.
          </li>
          <li>
            Don't forget to send out your characters on missions (Part-time
            jobs) - they give you resources and other useful stuff.
          </li>
          <li>
            Part-time jobs follow a cycle broken up into three 8-hour groups,
            the first of which begins at daily reset. Upon entering town, you
            will be asked to set a rest day for your souls. Every day in town is
            equal to 8 hours in real life, thus you will be able to set a
            timeslot of 8 hours where there will be no jobs (preferably the
            period where you cannot access game). The remaining two timeslots
            will have jobs based on how many job buildings you have set in your
            town, which will become unavailable once the 8 hour period they
            began in is up. The special buildings obtained from paid event
            passes do not follow this schedule however, as they will only
            provide their job once each day, but it will not vanish after 8
            hours, allowing you to send your souls on the job at any point
            during the 24 hour period.
          </li>
        </ul>
        <SectionHeader title="Cash shop" />
        <ul>
          <li>
            Spending $1 on anything gives you Talia, one of the strongest early
            game carries. Seems like JP server is instead giving Miriam as top
            up reward, which is mainly used in Raid Content and Fairy Gate
            instead of being universal character unlike Talia.
          </li>
          <li>
            The Achievement Pack 1-3 has the best value in the game (Initially
            only Achievement Pack 1 will be available, but as more and more
            story chapters are added, Pack 2 and 3 will be introduced).
          </li>
          <li>
            Both Monthly subs are worth their price, but Manon Pack gives you a
            lot of quality of life features on top of all the Everstones.
          </li>
          <li>
            The Soul Pass has a really good value for the price and gives you
            materials and more pulls.
          </li>
          <li>
            Seasonal Passes are one of best purchases cost to value ratio wise
            as they give out a copy of featured character (frequently being an
            angel or demon or chaos character) as well as two exclusive
            buildings for town, one which gives resources part time job and
            other which serves as character exclusive Big Manor (10% Attack
            increase etc), this is introduced few months later into game during
            various events such as Halloween, Christmas etc.
          </li>
          <li>
            Unit Ascension passes bring out good value for dolphins and whales
            alike, as they can stack up on pulls, get artifact shards and even
            some unit copies allowing easier maxing of the unit.
          </li>
          <li>
            For Heavy spenders, Spending on entries for Gachapon event is a
            worthwhile investment due to the infinitely refresh-able 40 Artifact
            Ticket prize, allowing you to gain them at a fraction of raw gem
            cost.
          </li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EsGuidesTricksPage;

export const Head: React.FC = () => (
  <Seo
    title="Tips and tricks | Eversoul | Prydwen Institute"
    description="Compilation of important tricks and tips for the game."
    game="eversoul"
  />
);
